<template>
	<div>
		<ZyroFieldToggle
			id="editBlockSlideshowIsAutoplayEnabledToggle"
			:label="$t('builder.editSlideshow.settings.autoplaySlideshow')"
			:checked="isSlideshowAutoplayEnabled"
			@input="setSlideshowSetting('isAutoplayEnabled', !isSlideshowAutoplayEnabled)"
		/>
		<ZyroSeparator />
		<ZyroFieldRange
			v-if="isSlideshowAutoplayEnabled"
			:label="$t('builder.editSlideshow.settings.howLongBetweenLoop')"
			:value="slideshowAutoplayIntervalSeconds"
			min="1"
			max="10"
			:units="$t('common.sec')"
			@input="setSlideshowSetting('autoplaySlidesIntervalSeconds', Number($event.target.value))"
		/>
		<ZyroFieldToggle
			id="editBlockSlideshowIsLoopEnabledToggle"
			:label="$t('builder.editSlideshow.settings.loopSlides')"
			:checked="isSlideshowLoopEnabled"
			@input="setSlideshowSetting('isLoopEnabled', !isSlideshowLoopEnabled)"
		/>
		<ZyroFieldToggle
			v-if="isMobileView"
			id="editBlockSlideshowIsMobileFullScreenHeightToggle"
			:label="$t('builder.editSlideshow.settings.fullSreenHeight')"
			:checked="isSlideshowMobileFullScreenHeightEnabled"
			@input="setSlideshowSetting(
				'isMobileFullScreenHeightEnabled', !isSlideshowMobileFullScreenHeightEnabled
			)"
		/>
	</div>
</template>
<script>

import { mapState } from 'vuex';

import { useEditBlockSlideshowSettings } from '@/components/builder-controls/edit-block-slideshow/use/useEditBlockSlideshowSettings';

export default {
	setup(props, context) {
		const {
			isSlideshowAutoplayEnabled,
			isSlideshowLoopEnabled,
			isSlideshowMobileFullScreenHeightEnabled,
			slideshowAutoplayIntervalSeconds,
			setSlideshowSetting,
		} = useEditBlockSlideshowSettings(props, context);

		return {
			isSlideshowAutoplayEnabled,
			isSlideshowLoopEnabled,
			isSlideshowMobileFullScreenHeightEnabled,
			slideshowAutoplayIntervalSeconds,
			setSlideshowSetting,
		};
	},
	computed: mapState('gui', ['isMobileView']),
};
</script>
