import {
	computed,
	ref,
} from '@vue/composition-api';
import { nanoid } from 'nanoid';

import {
	DEFAULT_SLIDE,
	DELETE_OPTION_ID,
	DUPLICATE_OPTION_ID,
	MANAGE_SLIDES_POPUP_OPTIONS,
	MAX_SLIDE_COUNT,
	MIN_SLIDE_COUNT,
} from '@/components/builder-controls/edit-block-slideshow/editBlockSlideshowConstants';
import { useEditBlockSlideshow } from '@/components/builder-controls/edit-block-slideshow/use/useEditBlockSlideshow';
import i18n from '@/i18n/setup';
import { cloneDeep } from '@/utils/object';

export const useManageBlockSlideshowSlides = (props, context) => {
	const { $store } = context.root;
	const {
		currentSlideshowActiveSlideIndex,
		setActiveSlide,
		websiteBlocks,
		currentBlockId,
	} = useEditBlockSlideshow(props, context);

	const isDeleteSlideModalVisible = ref(false);
	const slideToDelete = ref(null);

	const currentSlideshowSlides = computed(() => websiteBlocks.value[currentBlockId.value].slides);

	const currentSlideshowSlideCount = computed(() => currentSlideshowSlides.value.length);

	const currentSlideshowSlideNames = computed(
		() => currentSlideshowSlides.value.map((slide) => slide.name),
	);

	const hasCurrentSlideshowReachedMaxCount = computed(
		() => currentSlideshowSlideCount.value >= MAX_SLIDE_COUNT,
	);

	const hasCurrentSlideshowReachedMinCount = computed(
		() => currentSlideshowSlideCount.value <= MIN_SLIDE_COUNT,
	);

	const manageSlideshowPopupOptions = computed(
		() => MANAGE_SLIDES_POPUP_OPTIONS.filter((option) => {
			if (hasCurrentSlideshowReachedMinCount.value) {
				return option.id !== DELETE_OPTION_ID;
			}

			if (hasCurrentSlideshowReachedMaxCount.value) {
				return option.id !== DUPLICATE_OPTION_ID;
			}

			return option;
		}),
	);

	const toggleDeleteSlideModal = (slide = null) => {
		isDeleteSlideModalVisible.value = !isDeleteSlideModalVisible.value;
		slideToDelete.value = slide;
	};

	const setCurrentActiveSlideById = (slideId) => {
		setActiveSlide({
			slideshowId: currentBlockId.value,
			slideId,
		});
	};

	const updateCurrentSlideshowSlides = (newSlides) => {
		$store.commit('setBlockData', {
			blockId: currentBlockId.value,
			data: { slides: newSlides },
		});
	};

	const addSlide = () => {
		if (hasCurrentSlideshowReachedMaxCount.value) {
			return;
		}

		const newSlideshowSlideId = nanoid();
		const newSlide = {
			blockId: newSlideshowSlideId,
			name: `${i18n.t('common.slide')} ${currentSlideshowSlideCount.value + 1}`,
		};
		const slide = cloneDeep(DEFAULT_SLIDE.slide);
		const components = cloneDeep(DEFAULT_SLIDE.components);
		const slideComponents = Object.fromEntries(components
			.map((component) => {
				const componentId = nanoid();

				slide.components.push(componentId);
				slide.zindexes.push(componentId);

				return [
					componentId,
					component,
				];
			}));

		$store.dispatch('addBlockSlideshowSlide', {
			blockId: newSlideshowSlideId,
			slideshowBlockId: currentBlockId.value,
			block: slide,
			newSlide,
			components: slideComponents,
		});

		setCurrentActiveSlideById(newSlideshowSlideId);
	};

	const duplicateSlide = (slide) => {
		if (hasCurrentSlideshowReachedMaxCount.value) {
			return;
		}

		const slideBlockDuplicate = $store.getters.getBlockDuplicateById(slide.blockId);
		const newSlide = {
			blockId: slideBlockDuplicate.newBlockId,
			name: `${slide.name} - ${i18n.t('common.copy')}`,
		};

		$store.dispatch('duplicateBlockSlideshowSlide', {
			...slideBlockDuplicate,
			slideshowBlockId: currentBlockId.value,
			newSlide,
		});

		setCurrentActiveSlideById(newSlide.blockId);
	};

	const editSlide = ({
		oldValue,
		newValue,
	}) => {
		if (oldValue.name === newValue.name) {
			return;
		}

		const currentSlidesCopy = [...currentSlideshowSlides.value];

		currentSlidesCopy
			.find((slide) => slide.blockId === newValue.blockId).name = newValue.name;

		updateCurrentSlideshowSlides(currentSlidesCopy);
	};

	const removeSlide = (slideToRemove) => {
		$store.dispatch('removeBlockSlideshowSlide', {
			blockId: slideToRemove.blockId,
			slideshowBlockId: currentBlockId.value,
		});

		toggleDeleteSlideModal();
	};

	const manageSlidesValidator = (slideName) => {
		if (!slideName) {
			return {
				isValid: false,
				error: i18n.t('validate.emptyValue'),
			};
		}

		if (currentSlideshowSlideNames.value.includes(slideName)) {
			return {
				isValid: false,
				error: i18n.t('editSlideshow.manageSlides.validation.alreadyExists'),
			};
		}

		return {
			isValid: true,
			error: '',
		};
	};

	return {
		isDeleteSlideModalVisible,
		slideToDelete,
		currentSlideshowSlides,
		currentSlideshowSlideNames,
		currentSlideshowActiveSlideIndex,
		manageSlideshowPopupOptions,
		hasCurrentSlideshowReachedMaxCount,
		toggleDeleteSlideModal,
		setCurrentActiveSlideById,
		updateCurrentSlideshowSlides,
		addSlide,
		duplicateSlide,
		editSlide,
		removeSlide,
		manageSlidesValidator,
	};
};
