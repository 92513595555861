<template>
	<ZyroPopup
		class="popup"
		:show-close-button="false"
		type="no-padding"
		:offset="popupOffset"
		:on-toggle="handlePopupToggle"
		is-popup-content-flex-column
	>
		<template #trigger>
			<ZyroButton
				icon="settings"
				:title="$t('common.settings')"
			/>
		</template>
		<template #default="popup">
			<div class="popup__content">
				<ZyroButton
					v-for="{
						id, icon, title, text
					} in manageSlideshowPopupOptions"
					:key="`${id}-button`"
					class="z-body-small"
					theme="plain"
					:icon-left="icon"
					:title="title"
					@click.stop="handleOptionClick(popup, id)"
				>
					{{ text }}
				</ZyroButton>
			</div>
		</template>
	</ZyroPopup>
</template>

<script>
import { computed } from '@vue/composition-api';

import { useManageBlockSlideshowSlides } from '@/components/builder-controls/edit-block-slideshow/use/useManageBlockSlideshowSlides';
import {
	POPUP_POSITION_BOTTOM,
	POPUP_POSITION_TOP,
} from '@/utils/popupPosition';

export default {
	props: {
		onPopupOpen: {
			type: Function,
			default: () => null,
		},
		popupOpensToBottom: {
			type: Boolean,
			default: false,
		},
	},
	setup(props, context) {
		const { manageSlideshowPopupOptions } = useManageBlockSlideshowSlides(props, context);

		const popupOffset = computed(
			() => (props.popupOpensToBottom ? POPUP_POSITION_TOP : POPUP_POSITION_BOTTOM),
		);

		const handleOptionClick = (popup, optionId) => {
			popup.toggle();
			context.emit(optionId);
		};

		const handlePopupToggle = (isOpen, triggerPosition) => {
			if (isOpen) {
				props.onPopupOpen(triggerPosition);
			}
		};

		return {
			popupOffset,
			handleOptionClick,
			handlePopupToggle,
			manageSlideshowPopupOptions,
		};
	},
};
</script>

<style scoped lang="scss">
.popup {
	&__content {
		display: flex;
		flex-direction: column;
	}
}
</style>
