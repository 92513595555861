<template>
	<div
		class="style"
		:style="computedStyles"
	>
		<ZyroLabel>{{ $t('common.controls') }}</ZyroLabel>
		<div class="style__controls">
			<div
				v-for="(control, index) in CONTROLS"
				:key="`${control.isNavigationBulletsVisible}${index}`"
				class="style__control-container"
				:class="{ 'style__control-container--active': isNavigationControlSelected(control) }"
				@click="setNavigationControlVisibility(control)"
			>
				<div
					v-if="control.isNavigationArrowsVisible"
					class="style__navigation-arrows"
				>
					<ZyroSvg
						name="chevron-left"
						dimensions="8px"
					/>
					<ZyroSvg
						name="chevron-right"
						dimensions="8px"
					/>
				</div>
				<div
					v-if="control.isNavigationBulletsVisible"
					class="style__bullet-buttons"
				>
					<div
						v-for="(button, indexBulletButton) in BULLET_BUTTON_COUNT"
						:key="`${button}${indexBulletButton}`"
						class="style__bullet-button"
					/>
				</div>
			</div>
		</div>
		<div class="style__color-select">
			<ZyroLabel>{{ $t('builder.editSlideshow.settings.navigationArrowsColor') }}</ZyroLabel>
			<ZyroColorPicker
				:value="slideshowNavigationArrowsColor"
				:default-mode="COLOR_PICKER_MODE_FULL"
				@input="setSlideshowStyle('navigationArrowsColor', $event)"
			/>
		</div>
		<div class="style__color-select">
			<ZyroLabel>{{ $t('builder.editSlideshow.settings.navigationBulletsColor') }}</ZyroLabel>
			<ZyroColorPicker
				:value="slideshowNavigationBulletsColor"
				:default-mode="COLOR_PICKER_MODE_FULL"
				@input="setSlideshowStyle('navigationBulletsColor', $event)"
			/>
		</div>
		<ZyroFieldToggle
			v-if="!isMobileMode"
			id="isBlockSlideshowGridGapsEnabled"
			:checked="isSlideshowGridGapsEnabled"
			:label="$t('builder.editBlockTabLayout.toggleFieldLabel')"
			@change="toggleSlideshowGridGaps"
		/>
		<ZyroSeparator />
		<ZyroCssShorthandControl
			class="layout__padding"
			:label="$t('common.padding')"
			:value="slideshowPadding"
			:title-vertical="$t('common.vertical')"
			:title-horizontal="$t('common.horizontal')"
			units="px"
			input-type="number"
			@update:value="setSlideshowStyle('block-padding', $event)"
		/>
	</div>
</template>

<script>
import { computed } from '@vue/composition-api';
import { mapGetters } from 'vuex';

import { useEditBlockSlideshowSettings } from '@/components/builder-controls/edit-block-slideshow/use/useEditBlockSlideshowSettings';
import { COLOR_PICKER_MODE_FULL } from '@/components/global/zyro-color-picker/constants';

const CONTROLS = {
	allControls: {
		isNavigationBulletsVisible: true,
		isNavigationArrowsVisible: true,
	},
	navigationArrowsControls: {

		isNavigationBulletsVisible: false,
		isNavigationArrowsVisible: true,
	},
	bulletButtonControls: {
		isNavigationBulletsVisible: true,
		isNavigationArrowsVisible: false,
	},
};

const BULLET_BUTTON_COUNT = 3;

export default {
	setup(props, context) {
		const {
			currentBlockStyles,
			slideshowNavigationArrowsColor,
			slideshowNavigationBulletsColor,
			isSlideshowNavigationArrowsVisible,
			isSlideshowNavigationBulletsVisible,
			isSlideshowGridGapsEnabled,
			slideshowPadding,
			toggleSlideshowGridGaps,
			setSlideshowStyle,
			setSlideshowSetting,
		} = useEditBlockSlideshowSettings(props, context);

		const computedStyles = computed(() => ({
			'--navigationArrowsColor': currentBlockStyles.value.navigationArrowsColor,
			'--navigationBulletsColor': currentBlockStyles.value.navigationBulletsColor,
		}));

		const setNavigationControlVisibility = ({
			isNavigationBulletsVisible,
			isNavigationArrowsVisible,
		}) => {
			setSlideshowSetting('isNavigationBulletsVisible', isNavigationBulletsVisible);
			setSlideshowSetting('isNavigationArrowsVisible', isNavigationArrowsVisible);
		};

		const isNavigationControlSelected = (
			control,
		) => isSlideshowNavigationBulletsVisible.value === control.isNavigationBulletsVisible
			&& isSlideshowNavigationArrowsVisible.value === control.isNavigationArrowsVisible;

		return {
			computedStyles,
			slideshowNavigationArrowsColor,
			slideshowNavigationBulletsColor,
			slideshowPadding,
			setSlideshowStyle,
			toggleSlideshowGridGaps,
			setNavigationControlVisibility,
			isSlideshowGridGapsEnabled,
			isNavigationControlSelected,
			CONTROLS,
			BULLET_BUTTON_COUNT,
			COLOR_PICKER_MODE_FULL,
		};
	},
	computed: { ...mapGetters('gui', ['isMobileMode']) },
};
</script>

<style scoped lang="scss">
.style {
	$this: &;

	&__controls {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 16px;
		margin-bottom: 16px;
	}

	&__control-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 144px;
		height: 80px;
		padding: 8px 16px;
		cursor: pointer;
		border: 1px solid $grey-400;
		border-radius: 5px;
		transition: background-color 0.1s ease, border-color 0.1s ease;

		&:first-child {
			#{$this}__navigation-arrows {
				margin-top: auto;
			}
		}

		&--active {
			background: $grey-100;
			border-color: $accent-two;
		}
	}

	&__navigation-arrows {
		display: flex;
		justify-content: space-between;
		color: $grey-800;
	}

	&__bullet-buttons {
		display: grid;
		grid-template-columns: repeat(3, 5px);
		grid-gap: 4px;
		justify-content: center;
		margin-top: auto;
	}

	&__bullet-button {
		width: 5px;
		height: 5px;
		background-color: $grey-800;
		border-radius: 50px;
		opacity: 0.5;

		&:first-child {
			background-color: $grey-800;
			opacity: 1;
		}
	}

	&__color-select {
		display: flex;
		justify-content: space-between;
	}
}
</style>
