const RESERVED_SPACE_FOR_POPUP = 250;

export const POPUP_POSITION_TOP = {
	top: 20,
	right: 13,
};
export const POPUP_POSITION_BOTTOM = {
	bottom: 0,
	right: 16,
};

/**
 * By default, popup should be opened on the bottom of the item,
 * unless it's not enough space for popup to fit.
 * Example:
 * Open popup of the last item in scroll and it won't be visible, you'd have to scroll down
 *
 * Edge case: if user screen has an unnatural height screen open the menu to the bottom.
 *
 * / ---- Usage ---- /
 * Get height and top from getBoundingClientRect()
 * popupTriggerPositionTop - should be the getBoundingClientRect().top of the popup that
 * will be opened
 */
export const shouldPopupOpenToBottom = ({
	height: scrollAreaHeight,
	top: scrollAreaTop,
	reservedSpaceForPopup = RESERVED_SPACE_FOR_POPUP,
	popupTriggerPositionTop,
}) => {
	const popupTopPositionInScrollArea = popupTriggerPositionTop - scrollAreaTop;

	return scrollAreaHeight < reservedSpaceForPopup
		|| scrollAreaHeight > popupTopPositionInScrollArea + reservedSpaceForPopup;
};
