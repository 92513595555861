import i18n from '@/i18n/setup';

export const TABS = [
	{
		id: 'slides',
		title: i18n.t('common.slides'),
		component: 'EditBlockSlideshowSettingsTabSlides',
	},
	{
		id: 'style',
		title: i18n.t('common.style'),
		component: 'EditBlockSlideshowSettingsTabStyle',
	},
	{
		id: 'settings',
		title: i18n.t('common.settings'),
		component: 'EditBlockSlideshowSettingsTabSettings',
	},
];

export const MAX_SLIDE_COUNT = 10;
export const MIN_SLIDE_COUNT = 1;

export const DEFAULT_SLIDE = {
	slide: {
		type: 'BlockGrid',
		settings: {
			styles: {
				rows: 12,
				width: '1224px',
				'm-rows': '1',
				'row-gap': '16px',
				'row-size': '48px',
				'column-gap': '24px',
				'grid-width': '1224px',
				'column-count': '12',
				'block-padding': '16px 0',
				'content-width': '1600px',
				'content-padding': '16px',
				'm-block-padding': '60px 16px 60px 16px',
				oldContentWidth: '1400px',
			},
		},
		zindexes: [],
		background: {
			image: 'https://assets.zyrosite.com/gallery-assets/Frame-AoPlb3zeMWiKLJqW.png',
			current: 'image',
		},
		components: [],
	},
	components: [],
};

export const EDIT_OPTION_ID = 'edit';
export const CHANGE_BACKGROUND_OPTION_ID = 'change-background';
export const DUPLICATE_OPTION_ID = 'duplicate';
export const DELETE_OPTION_ID = 'delete';

export const MANAGE_SLIDES_POPUP_OPTIONS = [
	{
		id: EDIT_OPTION_ID,
		icon: 'rename',
		title: i18n.t('common.rename'),
		text: i18n.t('common.rename'),
	},
	{
		id: CHANGE_BACKGROUND_OPTION_ID,
		icon: 'droplet',
		title: i18n.t('common.changeBackground'),
		text: i18n.t('common.changeBackground'),
	},
	{
		id: DUPLICATE_OPTION_ID,
		icon: 'duplicate',
		title: i18n.t('common.duplicate'),
		text: i18n.t('common.duplicate'),
	},
	{
		id: DELETE_OPTION_ID,
		icon: 'trash',
		title: i18n.t('common.delete'),
		text: i18n.t('common.delete'),
	},
];
