<template>
	<ZyroPopupCard
		type="editor"
		:title="$t('builder.editSlideshow.settings.title')"
		:tabs="TABS"
		:current-tab="currentTab"
		@change="setCurrentTab"
		@close="$emit('close')"
	>
		<Component
			:is="currentTab.component"
			@close="$emit('close')"
		/>
	</ZyroPopupCard>
</template>

<script>
import { onMounted } from '@vue/composition-api';

import EditBlockSlideshowSettingsTabSettings from '@/components/builder-controls/edit-block-slideshow/EditBlockSlideshowSettingsTabSettings.vue';
import EditBlockSlideshowSettingsTabSlides from '@/components/builder-controls/edit-block-slideshow/EditBlockSlideshowSettingsTabSlides.vue';
import EditBlockSlideshowSettingsTabStyle from '@/components/builder-controls/edit-block-slideshow/EditBlockSlideshowSettingsTabStyle.vue';
import { TABS } from '@/components/builder-controls/edit-block-slideshow/editBlockSlideshowConstants';
import { useEditBlockSlideshowSettings } from '@/components/builder-controls/edit-block-slideshow/use/useEditBlockSlideshowSettings';

export default {
	components: {
		EditBlockSlideshowSettingsTabStyle,
		EditBlockSlideshowSettingsTabSettings,
		EditBlockSlideshowSettingsTabSlides,
	},
	props: {
		startTabId: {
			type: String,
			default: 'style',
		},
	},
	setup(props, context) {
		const {
			currentTab,
			setCurrentTab,
		} = useEditBlockSlideshowSettings(props, context);

		onMounted(() => {
			const startingTab = TABS.find((tab) => tab.id === props.startTabId);

			setCurrentTab(startingTab);
		});

		return {
			currentTab,
			setCurrentTab,
			TABS,
		};
	},
};
</script>
